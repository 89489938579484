/**
 * 此处可直接引用自己项目封装好的 axios 配合后端联调
 */

import request from './../utils/axios' // 组件内部封装的axios
import CommonService from '@/service/CommonService' // 调用项目封装的axios
// import request from "@/api/axios.js"       //调用项目封装的axios

// 获取验证图片  以及token
export function reqGet(data) {
    return CommonService.post(CommonService.getUrl('/code/create'), data)
//   return request({
//     // url: '/captcha/get',
//     url: CommonService.getUrl('/code/create'),
//     method: 'post',
//     data
//   })
}

// 滑动或者点选验证
export function reqCheck(data) {
    let url = CommonService.getUrl('/code/check');
    url = url + '?captchaType=' + data.captchaType + '&pointJson=' + data.pointJson + '&token=' + data.token;
    return CommonService.post(url, data)
}

